function setNewOrder(song, newOrder) {
  const newSong = {...song}
  newSong.order = newOrder
  return newSong
}

export const songs = [
  {
    order: 1,
    title: "Field Sobriety Practice",
    artist: "The Callous Daoboys",
    duration: "5:56",
    link: "https://www.youtube.com/watch?v=lL3ep9I_C90&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4ZWRLOs7c4drt9mKGc0Ds0",
    releaseDate: "Feb 2, 2024",
  },
  {
    order: 2,
    title: "Hereafter",
    artist: "Architects",
    duration: "4:14",
    link: "https://www.youtube.com/watch?v=nmlRsOEfer4&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/3ZztVuWxHzNpl0THurTFCv",
    releaseDate: "Jan 23, 2024",
  },
  {
    order: 3,
    title: "I Used To Have A Best Friend (But Then He Gave Me An STD)",
    artist: "Asking Alexandria",
    duration: "4:06",
    link: "https://www.youtube.com/watch?v=rDxN3VlAYnM&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/1caBfBEapzw8z2Qz9q0OaQ",
    releaseDate: "Jan 8, 2024",
  },
  {
    order: 4,
    title: "Yule Shoot Your Eye Out",
    artist: "Fall Out Boy",
    duration: "3:28",
    link: "https://www.youtube.com/watch?v=SWqz49SEqnk&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4UXqAaa6dQYAk18Lv7PEgX",
    releaseDate: "Dec 24, 2023",
  },
  {
    order: 5,
    title: "We Only Come Out At Night",
    artist: "Motionless In White",
    duration: "3:15",
    link: "https://www.youtube.com/watch?v=-mSvvenM0J4&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/6MwPCCR936cYfM1dLsGVnl",
    releaseDate: "Dec 23, 2023",
  },
  {
    order: 6,
    title: "Guillotine IV (The Final Chapter)", 
    artist: "Falling In Reverse",
    duration: "3:38",
    link: "https://www.youtube.com/watch?v=nb_kyA3mjIg&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/2CmaKO2zEGJ1NWpS1yfVGz",
    releaseDate: "Dec 22, 2023",
  },
  {
    order: 7,
    title: "Sambuka", 
    artist: "Pierce The Veil",
    duration: "2:28",
    link: "https://www.youtube.com/watch?v=2Fd2uMSa8gw&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4iJLPqClelZOBCBifm8Fzv",
    releaseDate: "Nov 21, 2023",
  },
  {
    order: 8,
    title: "A Boy Brushed Red Living In Black And White", 
    artist: "Underoath",
    duration: "4:29",
    link: "https://www.youtube.com/watch?v=I2UQ19dPCdY&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/3GzWhE2xadJiW8MqRKIVSK",
    releaseDate: "Aug 16, 2023",
  },
  {
    order: 9,
    title: "I Was Once, Possibly, Maybe, Perhaps A Cowboy King", 
    artist: "Asking Alexandria",
    duration: "3:41",
    link: "https://www.youtube.com/watch?v=ShTEkM-EFiw&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/1caBfBEapzw8z2Qz9q0OaQ",
    releaseDate: "Aug 14, 2023",
  },
  {
    order: 10,
    title: "Bloodmeat", 
    artist: "Protest The Hero",
    duration: "3:56",
    link: "https://www.youtube.com/watch?v=oHe6E4KW_HE&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/6z3BjfmgvDUIHaJ0UPTtrQ",
    releaseDate: "Jul 25, 2023",
  },
  {
    order: 11,
    title: "With Ears To See And Eyes To Hear", 
    artist: "Sleeping With Sirens",
    duration: "3:59",
    link: "https://www.youtube.com/watch?v=RAi7v9rzAwQ&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/3N8Hy6xQnQv1F1XCiyGQqA",
    releaseDate: "Sep 19, 2022",
  },
  {
    order: 12,
    title: "I Didn't Understand",
    artist: "Elliott Smith",
    duration: "2:16",
    link: "https://www.youtube.com/watch?v=G_9SRA5NR00&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/2ApaG60P4r0yhBoDCGD8YG",
    releaseDate: "Jan 17, 2022",
  },
]

export const demoSongs = [
]

export const oldSongs = [
  {
    order: songs.length + demoSongs.length + 1,
    title: "Southern Constillations / The Boy Who Could Fly",
    artist: "Pierce The Veil",
    duration: "5:56",
    link: "https://www.youtube.com/watch?v=HIgDgmK3QkU&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4iJLPqClelZOBCBifm8Fzv",
    releaseDate: "May 23, 2022",
    old: true,
  },
  {
    order: songs.length + demoSongs.length + 2,
    title: "Battery",
    artist: "Metallica",
    duration: "3:22",
    link: "https://www.youtube.com/watch?v=S3ddqwffn0k&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/2ye2Wgw4gimLv2eAKyk1NB",
    releaseDate: "Apr 17, 2022",
    old: true,
  },
  {
    order: songs.length + demoSongs.length + 3,
    title: "Hold On Till May",
    artist: "Pierce The Veil",
    duration: "4:21",
    link: "https://www.youtube.com/watch?v=IWHBxUOqMG4&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4iJLPqClelZOBCBifm8Fzv",
    releaseDate: "Apr 15, 2022",
    old: true,
  },
  {
    order: songs.length + demoSongs.length + 4,
    title: "Blindfolds Aside",
    artist: "Protest the Hero",
    duration: "1:42",
    link: "https://www.youtube.com/watch?v=8uKNAJyefNQ&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/6z3BjfmgvDUIHaJ0UPTtrQ",
    releaseDate: "Jan 17, 2022",
    old: true,
  },
  {
    order: songs.length + demoSongs.length + 5,
    title: "The New National Anthem",
    artist: "Pierce The Veil",
    duration: "3:56",
    link: "https://www.youtube.com/watch?v=-E6pjMWXpVE&ab_channel=JoeDobbelaar",
    artistHref: "https://open.spotify.com/artist/4iJLPqClelZOBCBifm8Fzv",
    releaseDate: "Jan 17, 2022",
    old: true,
  },
]

const celebrityTherapist = {
  title: "Celebrity Therapist",
  artist: "The Callous Daoboys",
  artistHref: "https://open.spotify.com/artist/4ZWRLOs7c4drt9mKGc0Ds0",
  year: 2022,
  mins: 36,
  secs: 37,
  themeColor: "#C76262",
  albumCover: "https://i.scdn.co/image/ab67616d00001e0254bec9052ee6bbcc2d86553f",
  songs: [
    {
      order: 1,
      title: "Violent Astrology",
      duration: "4:43",
      link: null,
    },
    {
      order: 2,
      title: "A Brief Article Regarding Time Loops",
      duration: "3:17",
      link: null,
    },
    {
      order: 3,
      title: "Beautifl Dude Missile",
      duration: "2:52",
      link: null,
    },
    {
      order: 4,
      title: "Title Track",
      duration: "6:36",
      link: null,
    },
    setNewOrder(songs[songs.length - 12], 5),
    {
      order: 5,
      title: "Field Sobriety Practice",
      duration: "4:08",
      link: null,
    },
    {
      order: 6,
      title: "The Elephant Man In The Room",
      duration: "4:16",
      link: null,
    },
    {
      order: 7,
      title: "What Is Delicious? Who Swarms?",
      duration: "3:16",
      link: null,
    },
    {
      order: 8,
      title: "Star Baby",
      duration: "5:37",
      link: null,
    },
  ]
}

const holyHell = {
  title: "Holy Hell",
  artist: "Architects",
  artistHref: "https://open.spotify.com/artist/3N8Hy6xQnQv1F1XCiyGQqA",
  year: 2018,
  mins: 42,
  secs: 41,
  themeColor: "#ffffffbb",
  albumCover: "https://i.scdn.co/image/ab67616d00001e020f08d4e55ebd266b7c3e8d74",
  songs: [
    {
      order: 1,
      title: "Death Is Not Defeat",
      duration: "3:45",
      link: null,
    },
    setNewOrder(songs[songs.length - 11], 2),
    {
      order: 3,
      title: "Mortal After All",
      duration: "3:39",
      link: null,
    },
    {
      order: 4,
      title: "Holy Hell",
      duration: "4:13",
      link: null,
    },
    {
      order: 5,
      title: "Damnation",
      duration: "4:08",
      link: null,
    },
    {
      order: 6,
      title: "Royal Beggars",
      duration: "4:01",
      link: null,
    },
    {
      order: 7,
      title: "Modern Misery",
      duration: "4:13",
      link: null,
    },
    {
      order: 8,
      title: "Dying To Heal",
      duration: "3:50",
      link: null,
    },
    {
      order: 9,
      title: "The Seventh Circle",
      duration: "1:48",
      link: null,
    },
    {
      order: 10,
      title: "Doomsday",
      duration: "4:08",
      link: null,
    },
    {
      order: 11,
      title: "A Wasted Hymn",
      duration: "4:34",
      link: null,
    },
  ]
}

const standUpAndScream = {
  title: "Stand Up And Scream",
  artist: "Asking Alexandria",
  artistHref: "https://open.spotify.com/artist/1caBfBEapzw8z2Qz9q0OaQ",
  year: 2009,
  mins: 48,
  secs: 15,
  themeColor: "#ffffff55",
  albumCover: "https://i.scdn.co/image/ab67616d0000b273db321359956f3f9f4c29372a",
  songs: [
    {
      order: 1,
      title: "Alerion",
      duration: "2:14",
      link: null,
    },
    {
      order: 2,
      title: "The Final Episode (Let's Change The Channel)",
      duration: "4:02",
      link: null,
    },
    {
      order: 3,
      title: "A Candlelit Dinner With Inamorta",
      duration: "4:03",
      link: null,
    },
    {
      order: 4,
      title: "Nobody Don't Dance No More",
      duration: "3:59",
      link: null,
    },
    {
      order: 5,
      title: "Hey There Mr. Brooks",
      duration: "4:09",
      link: null,
    },
    {
      order: 6,
      title: "Hiatus",
      duration: "1:45",
      link: null,
    },
    {
      order: 7,
      title: "If You Can't Ride Two Horses At Once... You Should Get Out Of The Circus",
      duration: "3:45",
      link: null,
    },
    {
      order: 8,
      title: "A Single Moment Of Sincerity",
      duration: "3:50",
      link: null,
    },
    {
      order: 9,
      title: "Not The American Average",
      duration: "4:39",
      link: null,
    },
    setNewOrder(songs[songs.length - 10], 10),
    {
      order: 11,
      title: "A Prophecy",
      duration: "3:33",
      link: null,
    },
    setNewOrder(songs[songs.length - 4], 12),
    {
      order: 13,
      title: "When Everyday's The Weekend",
      duration: "4:23",
      link: null,
    },
  ]
}

const believersNeverDie = {
  title: "Believers Never Die - Greatest Hits",
  artist: "Fall Out Boy",
  artistHref: "https://open.spotify.com/artist/4UXqAaa6dQYAk18Lv7PEgX",
  year: 2009,
  hours: 1,
  mins: 3,
  secs: null,
  themeColor: "#5482A9",
  albumCover: "https://i.scdn.co/image/ab67616d00001e02cecf2292d7fcd4b269cc736f",
  songs: [
    {
      order: 1,
      title: "Dead On Arrival",
      duration: "3:15",
      link: null,
    },
    {
      order: 2,
      title: "Grand Theft Autumn / Where Is Your Boy",
      duration: "3:11",
      link: null,
    },
    {
      order: 3,
      title: "Saturday",
      duration: "3:38",
      link: null,
    },
    {
      order: 4,
      title: "Sugar, We're Goin Down",
      duration: "3:50",
      link: null,
    },
    {
      order: 5,
      title: "Dance, Dance",
      duration: "3:01",
      link: null,
    },
    {
      order: 6,
      title: "A Little Less Sixteen Candles, A Little More 'Touch Me'",
      duration: "2:50",
      link: null,
    },
    {
      order: 7,
      title: "This Ain't A Scene, It's An Arms Race",
      duration: "3:33",
      link: null,
    },
    {
      order: 8,
      title: "Thnks fr th Mmrs",
      duration: "3:28",
      link: null,
    },
    {
      order: 9,
      title: '"The Take Over, The Breaks Over"',
      duration: "3:35",
      link: null,
    },
    {
      order: 10,
      title: "I'm Like A Lawyer With The Way I'm Always Trying To Get You Off (Me & You)",
      duration: "3:35",
      link: null,
    },
    {
      order: 11,
      title: "Beat It",
      duration: "3:49",
      link: null,
    },
    {
      order: 12,
      title: "I Don't Care",
      duration: "3:38",
      link: null,
    },
    {
      order: 13,
      title: "America's Suitehearts",
      duration: "3:40",
      link: null,
    },
    {
      order: 14,
      title: "What A Catch, Donnie",
      duration: "4:57",
      link: null,
    },
    {
      order: 15,
      title: "Alpha Dog",
      duration: "3:41",
      link: null,
    },
    {
      order: 16,
      title: '"From Now On We Are Enemies"',
      duration: "3:36",
      link: null,
    },
    setNewOrder(songs[songs.length - 9], 17),
    {
      order: 18,
      title: "Growing Up",
      duration: "2:54",
      link: null,
    },
  ]
}

const creatures = {
  title: "Creatures",
  artist: "Motionless In White",
  artistHref: "https://open.spotify.com/artist/6MwPCCR936cYfM1dLsGVnl",
  year: 2010,
  // hours: 1,
  mins: 46,
  secs: 2,
  themeColor: "#4B4390",
  albumCover: "https://i.scdn.co/image/ab67616d00001e0298ceb4669a4ab58b371dd6d9",
  songs: [
    {
      order: 1,
      title: "Immaculate Misconception",
      duration: "3:54",
      link: null,
    },
    setNewOrder(songs[songs.length - 8], 2),
    {
      order: 3,
      title: "London In Terror",
      duration: "3:41",
      link: null,
    },
    {
      order: 4,
      title: "Abigail",
      duration: "2:52",
      link: null,
    },
    {
      order: 5,
      title: "Creatures",
      duration: "3:47",
      link: null,
    },
    {
      order: 6,
      title: "Cobwebs",
      duration: "3:47",
      link: null,
    },
    {
      order: 7,
      title: ".Com Pt. II",
      duration: "3:49",
      link: null,
    },
    {
      order: 8,
      title: "Count Choculitis",
      duration: "4:03",
      link: null,
    },
    {
      order: 9,
      title: 'City Lights',
      duration: "3:07",
      link: null,
    },
    {
      order: 10,
      title: "Puppets (The First Snow)",
      duration: "4:20",
      link: null,
    },
    {
      order: 11,
      title: "Undead Ahead",
      duration: "3:57",
      link: null,
    },
    {
      order: 12,
      title: "Scissorhands (The Last Snow)",
      duration: "5:33",
      link: null,
    },
  ]
}

const justLikeYou = {
  title: "Just Like You",
  artist: "Falling In Reverse",
  artistHref: "https://open.spotify.com/artist/2CmaKO2zEGJ1NWpS1yfVGz",
  year: 2015,
  // hours: 1,
  mins: 52,
  secs: 27,
  themeColor: "#CE0C2B",
  albumCover: "https://i.scdn.co/image/ab67616d00001e027e8348f7c8b04198b1858757",
  songs: [
    {
      order: 1,
      title: "Chemical Prisoner",
      duration: "4:20",
      link: null,
    },
    {
      order: 2,
      title: "God, If You Are Above...",
      duration: "3:36",
      link: null,
    },
    {
      order: 3,
      title: "Sexy Drug",
      duration: "3:14",
      link: null,
    },
    {
      order: 4,
      title: "Just Like You",
      duration: "3:32",
      link: null,
    },
    setNewOrder(songs[songs.length - 7], 5),
    {
      order: 6,
      title: "Stay Away",
      duration: "3:21",
      link: null,
    },
    {
      order: 7,
      title: "Wait And See",
      duration: "4:38",
      link: null,
    },
    {
      order: 8,
      title: "The Bitter End",
      duration: "4:03",
      link: null,
    },
    {
      order: 9,
      title: "My Heart's To Blame",
      duration: "3:49",
      link: null,
    },
    {
      order: 10,
      title: "Get Me Out",
      duration: "3:45",
      link: null,
    },
    {
      order: 11,
      title: "Die For You",
      duration: "3:44",
      link: null,
    },
    {
      order: 12,
      title: "Brother",
      duration: "3:21",
      link: null,
    },
    {
      order: 13,
      title: "My Apocalypse II",
      duration: "3:45",
      link: null,
    },
    {
      order: 14,
      title: "Pray",
      duration: "3:37",
      link: null,
    },
  ]
}

const misadventures = {
  title: "Misadventures",
  artist: "Pierce The Veil",
  artistHref: "https://open.spotify.com/artist/4iJLPqClelZOBCBifm8Fzv",
  year: 2016,
  // hours: 1,
  mins: 43,
  secs: 36,
  themeColor: "#D43232",
  albumCover: "https://i.scdn.co/image/ab67616d00001e02feef5b9537d7b52555a42f17",
  songs: [
    {
      order: 1,
      title: "Dive In",
      duration: "4:52",
      link: null,
    },
    {
      order: 2,
      title: "Texas Is Forever",
      duration: "3:39",
      link: null,
    },
    {
      order: 3,
      title: "The Divine Zero",
      duration: "4:08",
      link: null,
    },
    {
      order: 4,
      title: "Floral & Fading",
      duration: "3:29",
      link: null,
    },
    {
      order: 5,
      title: "Phantom Power And Ludicrous Speed",
      duration: "3:50",
      link: null,
    },
    {
      order: 6,
      title: "Circles",
      duration: "3:44",
      link: null,
    },
    {
      order: 7,
      title: "Today I Saw The Whole World",
      duration: "3:41",
      link: null,
    },
    {
      order: 8,
      title: "Gold Medal Ribbon",
      duration: "3:58",
      link: null,
    },
    {
      order: 9,
      title: "Bedless",
      duration: "4:44",
      link: null,
    },
    setNewOrder(songs[songs.length - 6], 10),
    {
      order: 11,
      title: "Song For Isabelle",
      duration: "4:50",
      link: null,
    },
  ]
}

const theyreOnlyChasingSafety = {
  title: "They're Only Chasing Safety",
  artist: "Underoath",
  artistHref: "https://open.spotify.com/artist/3GzWhE2xadJiW8MqRKIVSK",
  year: 2004,
  // hours: 1,
  mins: 35,
  secs: 51,
  themeColor: "#3A5242",
  albumCover: "https://i.scdn.co/image/ab67616d00001e02c05f1b9f41f21afa225ce2ae",
  songs: [
    {
      order: 1,
      title: "Young And Aspiring",
      duration: "3:04",
      link: null,
    },
    setNewOrder(songs[songs.length - 5], 2),
    {
      order: 3,
      title: "The Impact Of Reason",
      duration: "3:23",
      link: null,
    },
    {
      order: 4,
      title: "Reinventing Your Exit",
      duration: "4:22",
      link: null,
    },
    {
      order: 5,
      title: "The Blue Note - Instrumental", 
      duration: "0:51",
      link: null,
    },
    {
      order: 6,
      title: "It's Dangerous Business Walking Out Your Front Door",
      duration: "3:58",
      link: null,
    },
    {
      order: 7,
      title: "Down, Set, Go",
      duration: "3:44",
      link: null,
    },
    {
      order: 8,
      title: "I Don't Feel Very Receptive Today",
      duration: "3:42",
      link: null,
    },
    {
      order: 9,
      title: "I'm Content With Losing",
      duration: "3:55",
      link: null,
    },
    {
      order: 10,
      title: "Some Will Seek Forgiveness, Others Escape",
      duration: "4:21",
      link: null,
    },
    {
      order: 11,
      title: "Song For Isabelle",
      duration: "4:50",
      link: null,
    },
  ]
}

const fortress = {
  title: "Fortress",
  artist: "Protest The Hero",
  artistHref: "https://open.spotify.com/artist/6z3BjfmgvDUIHaJ0UPTtrQ",
  year: 2004,
  hours: 1,
  mins: 19,
  // secs: 51,
  themeColor: "#994B7A",
  albumCover: "https://i.scdn.co/image/ab67616d00001e02f284f4455c0a4e28cba9f7bc",
  songs: [
    setNewOrder(songs[songs.length - 3], 1),
    {
      order: 2,
      title: "The Dissentience",
      duration: "4:24",
      link: null,
    },
    {
      order: 3,
      title: "Bone Marrow",
      duration: "5:31",
      link: null,
    },
    {
      order: 4,
      title: "Sequoia Throne",
      duration: "3:11",
      link: null,
    },
    {
      order: 5,
      title: "Palms Read", 
      duration: "5:06",
      link: null,
    },
    {
      order: 6,
      title: "Limb From Limb",
      duration: "4:22",
      link: null,
    },
    {
      order: 7,
      title: "Spoils",
      duration: "3:43",
      link: null,
    },
    {
      order: 8,
      title: "Wretch",
      duration: "4:12",
      link: null,
    },
    {
      order: 9,
      title: "Goddess Bound",
      duration: "3:35",
      link: null,
    },
    {
      order: 10,
      title: "Goddess Gagged",
      duration: "3:14",
      link: null,
    },
    {
      order: 11,
      title: "Song For Isabelle",
      duration: "4:50",
      link: null,
    },
  ]
}

const withEarsToSeeAndEyesToHear = {
  title: "With Ears To See And Eyes To Hear",
  artist: "Sleeping With Sirens",
  artistHref: "https://open.spotify.com/artist/3N8Hy6xQnQv1F1XCiyGQqA",
  year: 2004,
  // hours: 1,
  mins: 31,
  secs: 12,
  themeColor: "#B61C0D",
  albumCover: "https://i.scdn.co/image/ab67616d00001e02032fd22bde573d0746c87352",
  songs: [
    {
      order: 1,
      title: "If I'm James Dean, You're Audrey Hepburn",
      duration: "3:39",
      link: null,
    },
    {
      order: 2,
      title: "The Bomb Dot Com V2.0",
      duration: "3:31",
      link: null,
    },
    {
      order: 3,
      title: "You Kill Me (In A Good Way)",
      duration: "3:42",
      link: null,
    },
    {
      order: 4,
      title: "Let Love Bleed Red",
      duration: "3:42",
      link: null,
    },
    {
      order: 5,
      title: "Captain Tyin Knots VS Mr Walkway (No Way)", 
      duration: "3:22",
      link: null,
    },
    {
      order: 6,
      title: "Don't Fall Asleep At The Helm",
      duration: "2:14",
      link: null,
    },
    setNewOrder(songs[songs.length - 2], 7),
    {
      order: 8,
      title: "In Case Of Emergency, Dial 411",
      duration: "2:44",
      link: null,
    },
    {
      order: 9,
      title: "The Left Side Of Everywhere",
      duration: "2:59",
      link: null,
    },
    {
      order: 10,
      title: "Dance Party",
      duration: "1:32",
      link: null,
    },
    {
      order: 11,
      title: "Song For Isabelle",
      duration: "4:50",
      link: null,
    },
  ]
}

export const albums = [
  celebrityTherapist,
  holyHell,
  standUpAndScream,
  believersNeverDie,
  creatures,
  justLikeYou,
  misadventures,
  theyreOnlyChasingSafety,
  fortress,
  withEarsToSeeAndEyesToHear
]